import { TCardSize } from '@/components/Toggle/LayoutToggleGroup';
import Config from '@/constants/Config';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type TSetFunction = (fn: (state: TPrefStore) => Partial<TPrefStore>) => void;
type TPrefStoreFunction = (set: TSetFunction) => TPrefStore;
const isDevelopment = process.env.NODE_ENV === 'development';

type TPrefStore = {
    showSidebar: boolean;
    showMobileMenu?: boolean;
    cardSize?: TCardSize;
    setShowMobileMenu: (value: boolean) => void;
    setShowSidebar: (value: boolean) => void;
    setCardSize: (value: TCardSize) => void;
};

let prefStore: TPrefStoreFunction = (set: TSetFunction): TPrefStore => ({
    showSidebar: true,
    showMobileMenu: false,
    cardSize: 'sc',
    setShowMobileMenu: value =>
        set((state: TPrefStore) => ({ showMobileMenu: (state.showMobileMenu = value) })),
    setShowSidebar: value =>
        set((state: TPrefStore) => ({ showSidebar: (state.showSidebar = value) })),
    setCardSize: value => set((state: TPrefStore) => ({ cardSize: (state.cardSize = value) })),
});

if (isDevelopment) {
    prefStore = devtools(prefStore) as any;
}
prefStore = persist(prefStore, { name: Config.APP_NAME }) as any;
const usePrefStore = create<TPrefStore>(prefStore);

export { usePrefStore };
